import React from "react"
import {
  CustomField, DioboxEvent, Guest, Table,
} from "sharedTypes"
import usePermissions from "services/permissions"
import {
  Box, Divider, Grid, Stack,
} from "@chakra-ui/react"
import _ from "lodash"
import { asDateWithoutTZ } from "utilities/strings"
import useFeatureFlag from "services/featureFlags"
import AttributeList, { sortedValidAttributeTypes } from "./AttributeList"
import StatusUpdater from "./StatusUpdater"
import GuestLists from "./GuestLists"
import Header from "./Header"
import TicketSpend from "./TicketSpend"
import Seating from "./Seating"
import CheckInItem from "./CheckIn"
import Group from "./Group"
import FormResponses from "./FormResponses"
import ActivityList from "./ActivityList"
import Timestamps from "./Timestamps/Timestamps"

type Props = {
  currency: string
  guest: Guest
  tables: Table[]
  timezone: string
  updateGuest: (params: {}) => Promise<void>
  groupsEnabled: boolean
  event: DioboxEvent
}

const Show = ({
  currency,
  guest,
  tables,
  timezone,
  updateGuest,
  groupsEnabled,
  event,
}: Props) => {
  const {
    eventGroups,
    questionsAndAnswers,
    personInfo: {
      customFields,
      gender,
      birthDate,
      notes,
      email: primaryEmail,
      emailHardBounced,
      emailSoftBounced,
    },
    confirmedTime,
    createdTime,
    updatedAt,
  } = guest
  const { canEditGuests, canViewGuestDetails } = usePermissions()
  const { guestTimestamps: timestampsEnabled } = useFeatureFlag()

  const attributes = _.sortBy(
    [
      ...customFields.filter(({ value }) => value),
      gender && {
        type: "GenderField",
        title: "gender",
        value: gender,
      },
      birthDate && {
        type: "BirthDateField",
        title: "birth date",
        value: asDateWithoutTZ(birthDate),
      },
      notes && {
        type: "NotesField",
        title: "notes",
        value: notes,
      },
    ].filter(Boolean) as CustomField[],
    ({ type }) => sortedValidAttributeTypes.indexOf(type),
  )

  return (
    <Box px={4}>
      <Header
        guest={guest}
        displayMenu={canEditGuests}
        event={event}
      />
      <Grid gap={2} templateColumns={{ lg: "repeat(2, 1fr)" }}>
        <StatusUpdater
          guest={guest}
          updateGuest={updateGuest}
          disabled={!canEditGuests}
        />
        <Group guest={guest} enabled={groupsEnabled} disabled={!canEditGuests} />
        <Seating
          tables={tables}
          guest={guest}
          disabled={!canEditGuests}
        />
        <TicketSpend
          guest={guest}
          currency={currency}
          disabled={!canEditGuests}
          event={event}
        />
      </Grid>
      <GuestLists
        eventGroups={eventGroups}
        updateGuest={updateGuest}
        disabled={!canEditGuests}
      />
      <Stack mt={6} divider={<Divider />}>
        {attributes.length && (
          <AttributeList
            items={attributes}
            canViewGuestDetails={canViewGuestDetails}
            primaryEmail={primaryEmail}
            emailHardBounced={emailHardBounced}
            emailSoftBounced={emailSoftBounced}
          />
        )}
        {!!questionsAndAnswers.length && <FormResponses items={questionsAndAnswers} />}
        {timestampsEnabled && (
          <Timestamps
            confirmedTime={confirmedTime}
            createdTime={createdTime}
            updatedAt={updatedAt}
            timeZone={timezone}
          />
        )}
        <ActivityList guest={guest} timeZone={timezone} />
      </Stack>
      <CheckInItem guest={guest} />
    </Box>
  )
}

export default Show
